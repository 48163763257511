import React from "react";
import ReactGA from 'react-ga';
import {Link} from 'react-router-dom';
import HelpAndCurrency from './help-and-currency.js';
import AboutLinks from './footer-links/about-links.js';
import * as Utilities from './utilities.js';


export default class Checkout1 extends React.Component {
  constructor() {
    super()
    this.state = {
	  sku: "",
      brand: "",
	  name: "",
      part_num: "",
      msrp: 0,
      price: 0,
      products: [],
      updated: ""
    };

  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  componentDidMount() {
	var ga = Utilities.gaInitialize(ReactGA);
	Utilities.gaPageView(ga, "msa_page_view", "/product-list", "Product List");
	var url = Utilities.getApiURL('product_list.php?do=get', '');
    fetch(url, {method: 'GET', credentials: 'include'})
    .then(results => {
      return results.json();
    }).then((data) => {
        if(data.is_logged_in === false || data.admin === false){
			window.location = 'login?pageReturn=myaccount'
		} else {
			this.showProducts(data.product)
		}
    })
  } 
  updateProduct(id, field_id, field, e){
        var new_value  = document.getElementById(field_id).value.trim();
        var value_hidden  = document.getElementById('value_hidden').value.trim();

        
        if(new_value!=value_hidden){
            //alert("Update: id="+id+" msrp="+old_msrp+" new msrp="+new_msrp+" price="+old_price+" new price="+new_price);
            document.getElementById('value_hidden_after_change').value = new_value;
            this.postChanges(id, field, new_value);
        }else{
            //alert("Delay: id="+id+" msrp="+old_msrp+" new msrp="+new_msrp+" price="+old_price+" new price="+new_price);
                var icon = document.getElementById("icon"+id);
                var revert_icon = document.getElementById("hidden_icon"+id).value
                icon.src = 'https://www.msaironline.com/images/'+revert_icon;               
        }
  }
  setCurrentValue(id, field_id, field, e){
        //store the existing value of a cell to be edited so that we can compare things later
        document.getElementById('value_hidden').value = document.getElementById(field_id).value;
        document.getElementById('field_hidden').value = field.trim();
        //alert("hidden value is now "+document.getElementById('value_hidden').value);
  }
  setIconChanged(id, e){
    var icon = document.getElementById("icon"+id);
    icon.src = 'https://www.msaironline.com/images/services.png';
    icon.name = 'services.png';
  }
  postChanges(id, field, new_value){
 	var url = Utilities.getApiURL('product_list.php', '?do=update');
	var body = "&id="+id
					+"&field="+field
					+"&value="+new_value
    fetch(url, {
			method: 'POST',
			credentials: 'include',
			headers: {"Content-Type": "application/x-www-form-urlencoded"},
			body: body
		})
    .then(results => {
      return results.json();
    }).then((data) => {
        this.confirmUpdate(data);
    })
  }
  confirmUpdate(data){
        var value = document.getElementById("value_hidden_after_change").value;
        var icon = document.getElementById("icon"+data.product[0].id);
        var new_updated = document.getElementById("updated"+data.product[0].id);

        if(data.product[0].field === value) {
            icon.src = 'https://www.msaironline.com/images/ok.png';
            icon.name = 'ok.png';
            document.getElementById("hidden_icon"+data.product[0].id).value = 'ok.png';
            new_updated.textContent = data.product[0].updated;
        }else {
            icon.src = 'https://www.msaironline.com/images/high-priority.png';
            icon.name = 'high-priority.png';
            document.getElementById("hidden_icon"+data.product[0].id).value = 'high-priority.png';
        }
  }
  showProducts(a){
      var adminUrl = "https://engine.msaironline.com/prodManager.php?do=view&prodID=";
	  if(a){
        let products = a.map((pic) => {
            var msrp_id = "msrp"+pic.id;
            var price_id = "price"+pic.id;
            var updated_id = "updated"+pic.id;
            var part_num_id = "part_num"+pic.id;
            var cost_discount_id = "cost_discount"+pic.id;
            var service_life_id = "service_life"+pic.id;
            var icon_id = "icon"+pic.id;
            var hidden_icon_id = "hidden_icon"+pic.id;
            
            
            var icon_id = "icon"+pic.id;
		    return(
				<tr>
				  <td><a href={adminUrl+pic.id}>{pic.sku}</a></td>
				  <td><input id={part_num_id} type='text' name='part_num' size='12' defaultValue={pic.part_num} onBlur ={(e) => this.updateProduct(pic.id, part_num_id, "part_num", e)} 
                                                                                                  onFocus={(e) => this.setCurrentValue(pic.id, part_num_id, "part_num", e)}
                                                                                                  onInput={(e) => this.setIconChanged(pic.id, e)}/>
                  </td>
                  <td>{pic.brand} {pic.name}</td>
                  <td>
                    <input id={msrp_id} type='text' name='msrp' size='12' defaultValue={pic.msrp} onBlur ={(e) => this.updateProduct(pic.id, msrp_id, "msrp", e)} 
                                                                                                  onFocus={(e) => this.setCurrentValue(pic.id, msrp_id, "msrp", e)}
                                                                                                  onInput={(e) => this.setIconChanged(pic.id, e)}/>
                  </td>
                  <td>
                    <input id={price_id} type='text' name='price' size='12' defaultValue={pic.price} onBlur ={(e) => this.updateProduct(pic.id, price_id, "price", e)}
                                                                                                     onFocus={(e) => this.setCurrentValue(pic.id, price_id, "price", e)}
                                                                                                     onInput={(e) => this.setIconChanged(pic.id, e)}/>
                  </td>
                  <td>
                    <input id={cost_discount_id} type='text' name='price' size='12' defaultValue={pic.cost_discount} onBlur ={(e) => this.updateProduct(pic.id, cost_discount_id, "cost_discount", e)}
                                                                                                     onFocus={(e) => this.setCurrentValue(pic.id, cost_discount_id, "cost_discount", e)}
                                                                                                     onInput={(e) => this.setIconChanged(pic.id, e)}/>
                  </td>
                  <td>
                    <input id={service_life_id} type='text' name='price' size='12' defaultValue={pic.service_life} onBlur ={(e) => this.updateProduct(pic.id, service_life_id, "service_life", e)}
                                                                                                     onFocus={(e) => this.setCurrentValue(pic.id, service_life_id, "service_life", e)}
                                                                                                     onInput={(e) => this.setIconChanged(pic.id, e)}/>
                  </td>
                  <td id={updated_id}>{pic.updated}</td>
				  <td><img height='20' src='https://www.msaironline.com/images/cloud.png' name='cloud.png' id={icon_id}/>
				      <input id={hidden_icon_id} type='hidden' name='hidden_icon_name' value='cloud.png'/>
				  </td>
				</tr>

		    )
	  	})
		this.setState({products: products});
        console.log("state", this.state.products);
	  } else{
		  	let products = "Empty"
			this.setState({products: products});
	  }
  }


  render(){

    return(
          <div>
            <div className = 'content-area-container'>
                <div>
                    <table>
                		<tr>
				            <td>SKU</td>
				            <td>Part #</td>
                            <td>Brand & Name</td>
                            <td>MSRP</td>
                            <td>Price</td>
                            <td>% Off Cost</td>
                            <td>Service Life</td>
				            <td>Updated</td>
				            <td>&nbsp;</td>
				        </tr>
                        {this.state.products}
                    </table>
                    <input id='value_hidden' type='hidden' name='value_hidden' value=''/>
                    <input id='field_hidden' type='hidden' name='field_hidden' value=''/>
                    <input id='value_hidden_after_change' type='hidden' name='value_hidden_after_change' value=''/>
                </div>
            </div>
          </div>
    )
  }
}
