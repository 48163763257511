const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function getUrlParam(param) {
	var result = null,
       tmp = [];
	var items = window.location.search.substr(1).split("&");
	for (var index = 0; index < items.length; index++) {
		tmp = items[index].split("=");
		if (tmp[0] === param) result = decodeURIComponent(tmp[1]);
	}
	return result;
}
export function getBasicAnalytics(ga){
	return null;
}
export function gaInitialize(ga){
	ga.initialize('G-K58JX9GQK8');
	return ga;
}
export function gaPageView(ga, type, path, title){
	ga.send({ hitType: type, page: path, title: title });
	return null;
}
export function getApiURL(app, query) {
    if(isLocalhost){
	    var base = 'https://www.msaironline.com/qa1/api/';
	    //var base = 'http://127.0.0.1/www/msa/api/';
	}
	else 
	    var base = 'https://www.msaironline.com/api/';
	return base+app+query;
}

export function putInCart(prodID, qty, e){
	if(qty == null) {
		qty=1
	}
	var url = getApiURL('cart.php', '?id='+prodID+'&qty='+qty);
	fetch(url, {
		method: 'PUT',
		credentials: 'include'
	}).then(results => {
      return results.json();
    }).then((data) => {
    })
}


export const restRequest = (url, method, content, body) => (
  fetch(url, {
    method: method,
    headers: new Headers({
      'Content-Type': content,
      'Access-Control-Request-Method': method,
      // 'Authorization': localStorage.getItem(ACCESS_TOKEN)
    }),
    body
  })
  .then(response => Promise.all([response.status, response.json()])
  .then(([code, body]) => ({ code, body }))
  .catch(function(err) {
    console.log('Fetch Error :-S', err)
  })
));
