import React from "react";
import ReactGA from 'react-ga';
import {Link} from 'react-router-dom';
import HelpAndCurrency from './help-and-currency.js';
import AboutLinks from './footer-links/about-links.js';
import * as Utilities from './utilities.js';

export default class Checkout3 extends React.Component {
  constructor() {
    super()
    this.state = {
	  billing: null,
	  products: null,
	  totals: null,
	  tax_exempt: null,
	  first: null,
      last: null,
	  company: null,
      email: null,
      street1: null,
      street2: null,
      city: null,
      state: null,
      zipcode: null,
	  phone1: null,
	  phone2: null,
	  phone3: null,
	  button_active: true,

	  sfirst: null,
      slast: null,
	  scompany: null,
      sstreet1: null,
      sstreet2: null,
      scity: null,
      sstate: null,
      szipcode: null,
	  sp1:null,
	  sp2: null,
	  sp3: null,

      shipping: null,
	  exp_month: 1,
	  exp_year:(new Date().getFullYear()),
	  card_type:"",
	  card_number:"",
	  cvv:"",
	  terms_conds: false,
	  states: ['AL','AK','AZ','AR','CA','CO','CT','DE','DC','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY']
    };

  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  next(){
	  if(this.button_active === false)
		  return false;
	  else
		  this.button_active = false;
 	var url = Utilities.getApiURL('checkout.php', '?do=completePurchase');
	var body = "&first="+this.state.first
					+"&last="+this.state.last
					+"&email="+this.state.email
					+"&company="+this.state.company
					+"&street1="+this.state.street1
					+"&street2="+this.state.street2
					+"&city="+this.state.city
					+"&state="+this.state.state
					+"&zip="+this.state.zip
					+"&phone1="+this.state.phone1
					+"&phone2="+this.state.phone2
					+"&phone3="+this.state.phone3
					+"&exp_month="+this.state.exp_month
					+"&exp_year="+this.state.exp_year
					+"&card_type="+this.state.card_type
					+"&card_number="+this.state.card_number
					+"&cvv="+this.state.cvv
					+"&terms_conds="+this.state.terms_conds;
    fetch(url, {
			method: 'POST',
			credentials: 'include',
			headers: {"Content-Type": "application/x-www-form-urlencoded"},
			body: body
		})
    .then(results => {
      return results.json();
    }).then((data) => {
        this.handleResponse(data);
    })
  }
  handleResponse(data) {
	  if(data.error){
		  alert("Error: "+data.error.message);
		  this.button_active = true;
	  }
	  else
		  this.completeOrder(data);
  }
  // handleChange(event) {
  //   const target = event.target;
  //   const value = target.type === 'checkbox' ? target.checked : target.value;
  //   const name = target.name;
  //
  //   this.setState({
  //     [name]: value
  //   });
  // }
  // next(){
 	// var url = Utilities.getApiURL('checkout.php', '?do=completePurchase');
	// var body = "&first="+this.state.first
	// 				+"&last="+this.state.last
	// 				+"&email="+this.state.email
	// 				+"&company="+this.state.company
	// 				+"&street1="+this.state.street1
	// 				+"&street2="+this.state.street2
	// 				+"&city="+this.state.city
	// 				+"&state="+this.state.state
	// 				+"&zip="+this.state.zip
	// 				+"&phone1="+this.state.phone1
	// 				+"&phone2="+this.state.phone2
	// 				+"&phone3="+this.state.phone3
	// 				+"&exp_month="+this.state.exp_month
	// 				+"&exp_year="+this.state.exp_year
	// 				+"&card_type="+this.state.card_type
	// 				+"&card_number="+this.state.card_number
	// 				+"&cvv="+this.state.cvv
	// 				+"&terms_conds="+this.state.terms_conds;
  //   fetch(url, {
	// 		method: 'POST',
	// 		credentials: 'include',
	// 		headers: {"Content-Type": "application/x-www-form-urlencoded"},
	// 		body: body
	// 	})
  //   .then(results => {
  //     return results.json();
  //   }).then((data) => {
  //       this.handleResponse(data);
  //   })
  // }
  // handleResponse(data) {
	//   if(data.error)
	// 	  alert("Error: "+data.error.message);
	//   else
	// 	  this.completeOrder(data);
  // }
  componentDidMount() {
	var ga = Utilities.gaInitialize(ReactGA);
	Utilities.gaPageView(ga, "msa_page_view", "/checkout3", "Checkout 3");
		this.getShipping();
		this.getCart();
  }
  getShipping(){
	  var url = Utilities.getApiURL('checkout.php', '');
        fetch(url, {
			method: 'GET',
			credentials: 'include',
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		})
    .then(results => {
      return results.json();
    }).then(data => {
		if(data.is_logged_in === false){
			window.location = 'login?pageReturn=viewcart'
		}
		var states = this.state.states.map((state) =>{
			if(state===data.shipping.state)
				return(<option selected='selected'>{state}</option>);
			else
				return(<option>{state}</option>);
		});
		this.setState({states: states});

		this.setState({sfirst: data.shipping.first});
		this.setState({slast: data.shipping.last});
		this.setState({scompany: data.shipping.company});
		this.setState({sstreet1: data.shipping.street1});
		this.setState({sstreet2: data.shipping.street2});
		this.setState({scity: data.shipping.city});
		this.setState({sstate: data.shipping.state});
		this.setState({szip: data.shipping.zip});
		this.setState({sp1: data.shipping.phone1});
		this.setState({sp2: data.shipping.phone2});
		this.setState({sp3: data.shipping.phone3});
		//this.setState({tax_exempt: data.shipping.tax_exempt});
		this.setTaxExemptInput(data.shipping.tax_exempt);

		this.setState({first: data.shipping.first});
		this.setState({last: data.shipping.last});
		this.setState({email: data.shipping.email});
		this.setState({company: data.shipping.company});
		this.setState({street1: data.shipping.street1});
		this.setState({street2: data.shipping.street2});
		this.setState({city: data.shipping.city});
		this.setState({state: data.shipping.state});
		this.setState({zip: data.shipping.zip});
		this.setState({phone1: data.shipping.phone1});
		this.setState({phone2: data.shipping.phone2});
		this.setState({phone3: data.shipping.phone3});
		var billing = (  <fieldset>        <div className="form-group col-md-6">
                  <label className="CardType"><strong>* Card Type</strong>    <i className="fab fa-cc-mastercard" />  |  <i className="fab fa-cc-visa" /></label>
                  <select name='card_type' type="text" className="form-control" id="inputCardType" onChange={this.handleChange.bind(this)} >
                    <option defaultValue >Choose...</option>
                    <option value="MasterCard">MasterCard</option>
                    <option value="VISA">VISA</option>
                  </select>
                </div>

                <div className="form-group col-md-6" id="card-number-field">
                  <label className="cardNumber"><strong>* Card Number</strong></label>
                  <input name='card_number' type="text" className="form-control" id="inputCardNumber" placeholder="Credit Card Number" onChange={this.handleChange.bind(this)} />
                </div>

                <div className="form-group col-md-6" id="expiration-date">
                  <label className="expyDate"><strong>* Expiration Date</strong></label><br />
                  <select name='exp_month'onChange={this.handleChange.bind(this)} >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>

                  <select name='exp_year' className="expyYear" onChange={this.handleChange.bind(this)}>
                    <option value={(new Date().getFullYear())}>{(new Date().getFullYear())}</option>
                    <option value={(new Date().getFullYear())+1}>{(new Date().getFullYear())+1}</option>
                    <option value={(new Date().getFullYear())+2}>{(new Date().getFullYear())+2}</option>
                    <option value={(new Date().getFullYear())+3}>{(new Date().getFullYear())+3}</option>
                    <option value={(new Date().getFullYear())+4}>{(new Date().getFullYear())+4}</option>
                    <option value={(new Date().getFullYear())+5}>{(new Date().getFullYear())+5}</option>
					<option value={(new Date().getFullYear())+6}>{(new Date().getFullYear())+6}</option>
                  </select>
                </div>

                <div className="form-group col-md-6">
                  <label className="cvv"><strong>* Security Code (CVV)</strong></label>
                  <input name='cvv' type="text" className="form-control" onChange={this.handleChange.bind(this)} id="cvv" placeholder="CVV" />
                </div>

                <div className="form-group col-md-6">
                  <label className="inputFirstName"><strong>* First Name</strong></label>
                  <input type="text" name='first' className="form-control" onChange={this.handleChange.bind(this)} id="inputFirstName" defaultValue={data.shipping.first} />
                </div>

                <div className="form-group col-md-6">
                  <label className="inputLastName"><strong>* Last Name</strong></label>
                  <input type="text" name="last" onChange={this.handleChange.bind(this)} className="form-control" id="inputLastName" defaultValue={data.shipping.last} />
                </div>
				<div className="form-group col-md-6">
                  <label className="inputCompany"><strong>Company</strong></label>
                  <input type="text" name="comapny" onChange={this.handleChange.bind(this)} className="form-control" id="inputCompany" defaultValue={data.shipping.company} />
                </div>
                <div className="form-group col-md-6">
                  <label className="inputEmail"><strong>* Email</strong></label>
                  <input type="text" name="email" onChange={this.handleChange.bind(this)} className="form-control" id="inputEmail" defaultValue={data.shipping.email} />
                </div>
                <div className="form-group col-md-6">
                  <label className="inputAddress"><strong>* Address</strong></label>
                  <input type="text" name='street1' className="form-control" onChange={this.handleChange.bind(this)} id="inputAddress" defaultValue={data.shipping.street1} />
                </div>

                <div className="form-group col-md-6">
                  <label className="inputAddress2"><strong>Address 2</strong></label>
                  <input type="text" name='street2' className="form-control" onChange={this.handleChange.bind(this)} id="inputAddress2" defaultValue={data.shipping.street2} />
                </div>

                <div className="form-group col-md-6">
                  <label className="inputCity"><strong>* City</strong></label>
                  <input type="text" name='city' className="form-control" onChange={this.handleChange.bind(this)} id="inputCity" defaultValue={data.shipping.city} />
                </div>

                <div className="form-group col-md-4">
                  <label className="inputState" ><strong>* State</strong></label>
                  <select type="text" name='state' onChange={this.handleChange.bind(this)} id="inputState" className="form-control">
					{this.state.states}
                  </select>
                </div>

                <div className="form-group col-md-2">
                  <label className="inputZip"><strong>* Zip</strong></label>
                  <input name='zip' type="text" className="form-control" onChange={this.handleChange.bind(this)} id="inputZip" defaultValue={data.shipping.zip} />
                </div>


                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label className="inputPhone"><strong>* Phone</strong></label>
                      <input name="phone1" type="text" className="form-control" onChange={this.handleChange.bind(this)} id="inputPhone1" defaultValue={data.shipping.phone1} pattern="[0-9]{3}" />
                  </div>
                  <div className="form-group col-md-4" style={{visibility:'hidden'}}>
                    <label className="inputPhone2">Phone 2</label>
                      <input name="phone2" type="text" className="form-control" onChange={this.handleChange.bind(this)} id="inputPhone2" defaultValue={data.shipping.phone2} pattern="[0-9]{3}" />
                  </div>
                  <div className="form-group col-md-4" style={{visibility:'hidden'}}>
                    <label className="inputPhone3"><strong>Phone 3</strong></label>
                      <input name="phone3" type="text" className="form-control" onChange={this.handleChange.bind(this)} id="inputPhone3" defaultValue={data.shipping.phone3} pattern="[0-9]{4}" />
                  </div>
                </div></fieldset>);
				this.setState({billing: billing});
	})
  }
  getCart(data){
  	var url = Utilities.getApiURL('cart.php', '');
    fetch(url, {method: 'GET', credentials: 'include'})
    .then(results => {
      return results.json();
    }).then((data) => {
        this.setCart(data);
    })
  }
  setCart(data){
	  var error = null;
	  if(data.error){
		error = (<div>{data.error.message}</div>)
		this.setState({error: error});
	  }
	  if(data.product){
      let products = data.product.map((pic) => {
		return(
				<tr>
                  <td>{pic.prodName} {pic.prodID}</td>
                  <td>{pic.qty}</td>
                  <td >{pic.prodPrice}</td>
                  <td >{pic.amt}</td>
				</tr>
		)
	  	})
		this.setState({products: products});
        console.log("state", this.state.products);
	  } else{
		  	let products = null
			this.setState({products: products});
	  }
	  var totals = null
	  var discount = null
	  var min_order_fee = null
	  var tax = null
	  var shipping = null
	  var subtotal = null
	  if(data.totals) {
		  if(data.totals.discount > 0)
			  discount = (<div><b>Discount</b>: ${data.totals.discount} </div>)
		  if(data.totals.min_order_fee > 0)
			  min_order_fee = (<div><b>Min Order Fee</b>: ${data.totals.min_order_fee} </div>)
		  if(data.totals.taxes_and_handling > 0)
			  tax = (<div><b>Taxes & Handling</b>: ${data.totals.taxes_and_handling}</div>)
		  if(data.totals.freight > 0)
			  shipping = (<div><b>{data.totals.shipping_method}</b>: ${data.totals.freight}</div>)
		  if(data.totals.cart_subtotal > 0)
			  subtotal = (<div><b>Subtotal</b>: ${data.totals.cart_subtotal}</div>)
		  totals = (
					<div>
						{subtotal}
						{min_order_fee}
						{discount}
						{tax}
						{shipping}
						<b>Total</b>: ${data.totals.total.toFixed(2)}
					</div>
			  )
	  }
	  this.setState({totals: totals});

  }
  setBilling(){
  }
  completeOrder(data){
	  if(data.order.status === true && data.order.invoice > 10000){
		 //alert("Order successful");
		 window.location = "vieworder?id="+data.order.invoice+"&confirm=true";
	  } else{
		 //alert("An unexpected error has occured.  Please retry the checkout process or call us at 1-800-MSAIR99.");
		 window.location = "viewcart";
	  }
  }
  setTaxExemptInput(a) {
		var tax_exempt = null
	  	if(a === "true") {
			//alert("Tax Exempt: TRUE|"+a);
			tax_exempt = (
				<div>
                    <label className="form-check-label">
                      <p>This order is tax exempt and you will be contacted to provide proof of exemption.</p>
                    </label>
				</div>)
			
		}
		this.setState({tax_exempt: tax_exempt});
  }
  close_modal(){
	  const modal = document.getElementById("myModal");
	  modal.style.display = "none";
  }
  showTsAndCs(){
	// Get the modal
	const modal = document.getElementById("myModal");
	// Get the <span> element that closes the modal
	const span = document.getElementsByClassName("close")[0];
	modal.style.display = "block";
	// When the user clicks on <span> (x), close the modal
	span.onclick = function() {
	  modal.style.display = "none";
	}
	// When the user clicks anywhere outside of the modal, close it
	window.onclick = function(event) {
	  if (event.target == modal) {
		modal.style.display = "none";
	  }
	}	  
  }  
  render(){

    return(
      <div>

        <div className = 'content-area-container'>
          <div className="checkout-header-image">
            <img src='https://www.msaironline.com/images/purchase03.gif' alt='Purchase Information' />
          </div>

          <div className="replacement_parts_header">
            <h1>Billing Information</h1>
          </div>


			  {this.state.billing}



            <div className="replacement_parts_header">
              <h1>Shipping Information</h1>
            </div>

              <fieldset>

                <div className="form-group col-md-6">
                  <label className="inputFirstName"><strong>First Name</strong></label>
                  <input readonly='readonly' type="text" className="form-control" id="inputFirstName" value={this.state.sfirst} />
                </div>

                <div className="form-group col-md-6">
                  <label className="inputLastName"><strong>Last Name</strong></label>
                  <input readonly='readonly' type="text" className="form-control" id="inputLastName" value={this.state.slast} />
                </div>

                <div className="form-group col-md-6">
                  <label className="inputAddress"><strong>Address</strong></label>
                  <input readonly='readonly' type="text" className="form-control" id="inputAddress" value={this.state.sstreet1} />
                </div>

                <div className="form-group col-md-6">
                  <label className="inputAddress2"><strong>Address 2</strong></label>
                  <input readonly='readonly' type="text" className="form-control" id="inputAddress2" value={this.state.sstreet2} />
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="inputCity"><strong>City</strong></label>
                    <input readonly='readonly' type="text" className="form-control" id="inputCity" value={this.state.scity} />
                  </div>

                <div className="form-group col-md-4">
                  <label className="inputState"><strong>State</strong></label>
				  <input readonly='readonly' type="text" className="form-control" value={this.state.sstate} />
                </div>

                <div className="form-group col-md-2">
                  <label className="inputZip"><strong>Zip</strong></label>
                  <input readonly='readonly' type="text" className="form-control" id="inputZip" value={this.state.szip} />
                </div>

                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label className="inputPhone"><strong>Phone</strong></label>
                      <input name="phone1" readonly='readonly' type="text" className="form-control" id="inputPhone1" value={this.state.sp1} pattern="[0-9]{3}" />
                  </div>
                  <div className="form-group col-md-4" style={{visibility:'hidden'}}>
                    <label className="inputPhone2">Phone 2</label>
                      <input name="phone2" readonly='readonly' type="text" className="form-control" id="inputPhone2" value={this.state.sp2} pattern="[0-9]{3}" />
                  </div>
                  <div className="form-group col-md-4" style={{visibility:'hidden'}}>
                    <label className="inputPhone3"><strong>Phone 3</strong></label>
                      <input name="phone3" readonly='readonly' type="text" className="form-control" id="inputPhone3"value={this.state.sp3} pattern="[0-9]{4}" />
                  </div>
                </div>

                <div>
                  <a href='checkout1'><h4>Edit Shipping Address</h4></a></div>
                </div>

              <div className="order-summary-table">
                <div className="replacement_parts_header">
                  <h1>Review Order</h1>
                </div>
                <table className="table table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Unit Price</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
				  {this.state.products}
                  </tbody>
                </table>

              </div>
				<div className="review-order-subtotals">
				{this.state.totals}
				{this.state.tax_exempt}
				</div>
              <div className="terms-and-conditions-checkbox">
                <div className="form-check">
                  <input name='terms_conds' className="form-check-input" type="checkbox" id="terms-checkbox" onChange={this.handleChange.bind(this)}/>
                    <label className="form-check-label">
                      <p onClick={(e) => this.showTsAndCs()}>I agree to the <b>Order Terms & Conditions</b></p>
                    </label>
                  </div>
                </div>


                <div className="order-confirmation-button" id="pay-now">
                  <button className="btn btn-primary" type="submit" onClick={(e) => this.next()} id="confirm-purchase"><h4>Confirm</h4></button>
                </div>








              </fieldset>














            <div className="FooterLinks1">
              <HelpAndCurrency />
            </div>

            <div className="FooterLinks2">
              <AboutLinks /> 
            </div>
          </div>

          <div className="about-footer">
            <div className="terms">
              <p><a href="/terms" rel="noopener noreferrer">Terms of Use</a> | <a href="/privacy" rel="noopener noreferrer">Privacy Policy</a></p>
            </div>
            <div className="copyright">
              <p>&copy; 2024 - MS Air, Inc. | <Link to="/">Home</Link></p>
            </div>
          </div>
		  
		  
<dialog  id="myModal" class="modal">
  <div class="modal-content">
  
    <span class="close">&times;</span>
	<h1>Order Terms & Conditions</h1>
	<div className="terms-para">


<h3><strong>Return Processing</strong></h3>
				<p>Please use our contact us form to request a return and refund. Only new (unused) products are eligible for returns and refunds. You have 30 days from the time of shipment to request a return and refund. The product is to be returned freight pre-paid. You are responsible for shipping costs and there may be up to a 15% restocking fee.</p>
				
				<p>Please allow 7-10 business days for products to be processed after being received. Due to product availability, some repair/replacement returns may be delayed or changed to refund. Once your return has been processed, we will send you an email regarding your return.</p>
			<h3><strong>Refunds</strong></h3>

				<p>Once your return has been processed, refunds will be issued back to your original payment method. If the original payment method is no longer available, we will mail you a check to the billing address listed on your account.</p>

			<h3><strong>Replacements</strong></h3>
				<p>Once your return has been processed, a replacement will be processed and shipped within approximately 1-2 business days. If we no longer carry a product that is sent in for replacement, or if that product is simply out of stock, we will issue you a refund.</p>

				<p>Products eligible for a replacement may only be replaced with the same product. We currently do not allow exchanges or returns for replacements for different products.</p>


          <h3><strong>OBTAINING YOUR CONSENT</strong></h3>
		  

          <p>By using our Web site, you give MS Air Inc. permission to collect information about the pages served to you as an anonymous user for the purpose of calculating aggregate site statistics. If you register at MS Air Inc., you authorize MS Air to use information you provide to personalize the information we deliver to you and to use your demographic information when calculating aggregate customer data. MS Air may occasionally amend this policy. We suggest that you bookmark this page and check to see if it has changed from time to time.</p>



          <h3><strong>Disclaimer</strong></h3>

		  <p>We use reasonable precautions to keep the personal information you disclose both in your browsing and your purchases. However, we are not responsible for any breach of security.</p>

		  

        <p>MSAirOnline.com HEREBY EXPRESSLY DISCLAIMS ALL WARRANTIES EITHER EXPRESSED OR IMPLIED WARRRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. THIS DISCLAIMER BY THE SELLER IN NO WAY AFFECTS THE TERMS OF THE MANUFACTURER'S WARRANTY IF ANY. THE SELLER RETAINS TITLE TO GOODS HEREIN BEING PURCHASED UNTIL GOODS ARE PAID FOR BY THE PURCHASER AND AT THAT TIME TITLE PASSES TO THE PURCHASER. IF GOODS HEREIN BEING PURCHASED ARE BEING PURCHASED FOR PURPOSES OF EXPORT, PURHASER MUST OBTAIN FROM THE UNITED STATES FEDERAL GOVERNEMENT CERTIN EXPORT DOCUMENTATION BEFORE SHIPPING TO A FOREIGN COUNTRY. IN ADDITION, MANUFACTURERS' WARRANTIES FOR EXPORTED GOODS MAY VARY OR EVEN BE NULL AND VOID. IF YOU HAVE QUESTIONS, PLEASE INQUIRE. ANY AND ALL LIABILITY IS ONLY FOR THE PRODUCTS PURCHASED.</p>



          <p>ALL BRANDS AND MODELS REMAIN TRADEMARKS OF THEIR RESPECTIVE OWNERS, AND ARE LISTED FOR REFERENCE AND COMPARISON PURPOSES ONLY. WE MAKE EVERY EFFORT TO ENSURE THAT THE INFORMATION CONTAINED ON THIS SITE IS ACCURATE, BUT WE TAKE NO RESPONSIBILITY FOR FAULTY, OUT DATED, OR OTHERWISE INACCURATE INFORMATION ON THIS SITE, INCLUDING PRICING, PRODUCT EDITORIALS, PRODUCT SPECIFICATIONS, PRODUCT PICTURES AND AVAILABILITY. MSAirOnline.com SHALL NOT BE LIABLE FOR ANY INCIDENTAL OR CONSEQUENTIAL DAMAGES CAUSED DIRECTLY OR INDIRECTLY BY ANYTHING ON THIS SITE.</p>



          <p>IN ADDITION, FOR ALL PRICES AND PRODUCTS, WE RESERVE THE RIGHT TO MAKE ADJUSTMENTS DUE TO CHANGING MARKET CONDITIONS, ERRORS, OR TYPOGRAPHICAL ERRORS IN ADVERTISEMENTS. MSAirOnline.com IS NOT RESPONSIBLE FOR MANUFACTURER PRICE CHANGES, WHICH MAY OCCUR AT ANY TIME WITHOUT NOTICE. FINALLY, MSAirOnline.com RESERVES THE RIGHT TO DISCONTINUE PRODUCTS AT ANY TIME WITH NO PRIOR WARNING (IN SUCH CASES, THE CUSTOMER WILL BE CONTACTED TO SECURE PRIOR APPROVAL TO PROCESS THE ORDER AT THE NEW PREVAILING PRICES).</p>



          <p>MSAirOnline.com DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM MSAirOnline.com ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. MSAirOnline.com WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, AND CONSEQUENTIAL DAMAGES.</p>


			
        </div>
	
	<div className="modal-body">
		<p><button onClick={(e) => this.close_modal()}>Close</button></p>
	</div>
	
  </div>
</dialog>


      </div>

	  
    )
	
  }
}
