import React from "react";
import ReactGA from 'react-ga';
import {Link} from 'react-router-dom';
// import FooterLinks from './footer-links/footer-links.js';
import HelpAndCurrency from './help-and-currency.js';
import AboutLinks from './footer-links/about-links.js';
import * as Utilities from './utilities.js';

export default class PrivacyPolicy extends React.Component {
  constructor() {
    super()
    this.state = {
	  pdf_file: "",
	  products: [],
    };

  }
	componentDidMount() {

	var url = Utilities.getApiURL('get_pdf.php', '?id='+Utilities.getUrlParam('file'));
	fetch(url, {
		method: 'GET',
		credentials: 'include'
	}).then(results => {
      return results.json();
    }).then((data) => {
		var pdf_blob  = null;
		data.file.map((file) => {
            this.showPdfModal(file);
		})

        if(data.category){
            this.getProducts(data.category[0]);
        }
        
        	    //set page title
        document.title = "MS Air "+data.file[0].file_name;
    
        //Set product meta keywords        
        //var meta = document.getElementById('meta_keywords');
        //meta.setAttribute('name', 'keywords');
        //meta.setAttribute('content', pic.prodSearchKey);

        //Set product meta descriptino        
        //var meta = document.getElementById('meta_description');
        //meta.setAttribute('name', 'description');
        //meta.setAttribute('content', "MS Air "+pic.prodName+" "+pic.prodSku+" "+pic.prodDesc);
	    
	    var ga = Utilities.gaInitialize(ReactGA);
	    Utilities.gaPageView(ga, "msa_page_view", "/pdf_redirect?file="+data.file[0].file_id, "PDF Redirect");

    })


}//end component did mount
close_modal(){

	  const modal = document.getElementById("myModal");
	  modal.style.display = "none";

}

showPdfModal(file){
    var pdf_file = file.file_contents;
    this.setState({pdf_file: pdf_file});
    document.getElementById('pdf_modal').src='data:application/pdf;base64,'+pdf_file;

	// Get the modal
	const modal = document.getElementById("myModal");

	// Get the <span> element that closes the modal
	const span = document.getElementsByClassName("close")[0];

    //document.getElementById('pdf_modal').data = file;

    //display the modal
    modal.style.display = "block";

	// When the user clicks on <span> (x), close the modal
	span.onclick = function() {
	  modal.style.display = "none";
	}


	// When the user clicks anywhere outside of the modal, close it

	window.onclick = function(event) {
	  if (event.target == modal) {
		modal.style.display = "none";


	  }
	}	  

}
getProducts(pic){
		var products = null;
		var productsSection = null;
		if(pic.product) {
			products = pic.product.map((product) => {
			var productUrl = "product?id=";
			var message = null;
			if(pic.message){
				message = (pic.message)
			}
			var prodAttributes = null;
			if(product.attributes) {
				prodAttributes = product.attributes.map((attribute) => {
				return (
					<li><strong>{attribute.field}</strong>: {attribute.value}</li>
				)
				});
			}
            return (





                <div className="individual-product-details2">

                  <div className="product-image">
                    <a href={productUrl+product.prodID+"&n="+product.urlName}><img src={product.icon} alt='' /></a>
                    
                  </div>

                  <div className="product-title">
                    <h2 className="individual-product-title"><a href={productUrl+product.prodID+"&n="+product.urlName}>{product.prodName}</a></h2>
					<div className="catalog-number"><h5>Catalog Number:<br />{product.prodSku}</h5></div>
                  </div>
                    <img src={product.brandIcon} alt={product.brandName}></img>

                    {/* <h4 className="product-brand-title"><a href={productUrl+product.prodID}><img src={product.brandIcon} alt={product.brandName}/></a></h4> */}

                    <h2 className="individual-product-actual-price"><strong>${product.prodPrice}</strong></h2>
                      <br />
                      <br />
                    <div className="individual-product-description">
                      <ul>
                        {prodAttributes}
                        {/*<li>{product.prodDesc}</li>*/}
                          <br />
                        <li><a href={productUrl+product.prodID+"&n="+product.urlName}>More Info >></a></li>
                          <br />
                        <li>{message}</li>
                          <br />
                        {/*<li>{product.prodLongDesc}</li>*/}
                      </ul>
                    </div>

                  </div>



			)});
			productsSection = (
				<div id="individual_product_page2">
          <div className="individual-product2">
					       {products}
				  </div>
				</div>

			)
		}
		this.setState({products: products});
}
  render(){

    return(
        <div className="content-area-container2" style={{width:'100%'}}>
            <div id="individual_product_page" style={{width:'100%', height:'100%'}}>
                <div className="container1">
                 <div className="container2">
                  {this.state.products}
                 </div>
               </div>
            </div>


        <dialog  id="myModal" class="modal">
                    <p><span class="close">&times;</span></p>
	                <embed class='embedPdfModal' id='pdf_modal' src=''  type="application/pdf" style={{}}></embed>
		            <p><button onClick={(e) => this.close_modal()}>Close</button></p>
        </dialog>



        </div>
    )
  }
}
